import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import { FeaturesHeroWave } from 'src/components/Svg'

import s from './NotFound.module.scss'

const FeaturesHero = () => (
  <div className={s.heroSection}>
    <Fade top cascade>
      <FeaturesHeroWave className={s.heroIcon} />
    </Fade>

    <div className={s.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-4">
            <Fade left cascade>
              <div className={s.left}>
                <h1 className={s.title}>Not found</h1>
              </div>
            </Fade>
          </div>
          <div className="col-md-7 col-lg-8">
            <Fade right cascade>
              <span className={s.error404}>404</span>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  </div>
)

FeaturesHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  isInvoicing: PropTypes.bool
}

export default FeaturesHero
